import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '../components/block-content';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Helmet from 'react-helmet';
import Layout from '../containers/layout';
import './privacy-policy.scss';

interface IProps {
  errors: Error[];
  data: {
    page: {
      title: string;
      _rawBody: string;
    };
    people: {};
  };
}

export const query = graphql`
  query OurDailyPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)ourDailyPlanetAnnoucement2021/" }) {
      id
      title
      _rawBody
    }
  }
`;

const OurDailyPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const page = data && data.page;
  return (
    <Layout>
    <Helmet>
    <title>Our Daily Planet Merges with World War Zero</title>
    </Helmet>
      <SEO title="Our Daily Planet Merges with World War Zero Organizations Join Forces to Expand the Climate Change Conversation" />
      <Container>
        <div className="privacy-policy--root dailypr">
        <h1>Our Daily Planet Merges with World War Zero</h1>
        <h3>Organizations Join Forces to Expand the Climate Conversation.</h3>
         <time datetime="2021-08-17">August 31, 2021</time>
          <p>We could not be more excited to announce that, World War Zero and the environmental media nonprofit, <a target="_blank" href="https://www.ourdailyplanet.com/">Our Daily Planet</a>, will become one.
          </p>
          <p>Our Daily Planet (ODP), was founded to provide a broad audience with an environmental news source that’s easy to consume, breaks down complicated topics, gives context; and explains how climate change is connected to social justice, national security, public health, the economy, and more. Since its inception, ODP has provided the information necessary to stay informed on climate issues as well as ways by which we can all make a difference.
          </p>
          <p>It’s no coincidence that this sounds a lot like World War Zero’s (WW0) mission. WW0 was co-founded in 2019 by former Secretary of State <a rel="noreferrer" href="https://act.worldwarzero.com/spec/">John Kerry</a>, former EPA Administrator Gina McCarthy, and leaders spanning science and government, both Republican and Democrat, entertainment and business to work with partners and well-known influencers to find common ground on climate action, and broaden what it means to be an environmentalist. ODP was launched in early 2018 to change how climate change and conservation issues are discussed in politics and the media. We know that the synergy of WW0 and ODP will strengthen our collective ability to be a steadfast voice for environmental action as these issues grow increasingly urgent in the years ahead. In short, we were both founded by people committed to a common mission, and that mission continues. 
</p>
          <p>We hope WW0’s merger with ODP sparks more climate conversations, and inspires action as to how we can contribute to our planet’s wellbeing in big and small ways.
</p>
          <p>ODP will continue delivering the content that its readers have come to depend on, written by the voices they’ve grown to know -- with a slightly refreshed look and additional content from WW0. In the lead up to COP26 in Glasgow watch for our two organizations to continue integrating over -- we’ll be fighting together to make the biggest impact possible.
</p>
<p>As we look to this next step, we wanted to sincerely thank you for reading and supporting WW0 and hope you will continue to rely on us as a trusted source of news and analysis for many years to come.
</p>
        </div>
      </Container>
    </Layout>
  );
};

export default OurDailyPage;